<!--会员等级-->
<template>
  <div class="balance">
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column label="会员等级" align="center" prop="levelName">
      </el-table-column>
      <el-table-column label="更新时间" align="center" prop="addDt">
      </el-table-column>
      <el-table-column label="升级会员间隔时长（天）" align="center" prop="intervalDay">
    </el-table-column>
    </el-table>
   
  </div>
</template>
<script>
import { pickerOptions } from "@/db/objs";
import { getUserLvRecord } from "@/api/user/user.js";

export default {
  data() {
    return {
      pickerOptions,

      tableParams: {
        page: 1,
        pageSize: 10,
        memberId: "",
      },
      tableData: [],
      total: 0,
      currentPage: 1,
    };
  },
  created() {

    this.tableParams.memberId = this.$route.query.userId;
  },
  mounted() {
    this.getUserLvRecord();
  },
  methods: {
    // 用户账户明细
    getUserLvRecord() {
      getUserLvRecord(this.tableParams).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data;
          this.total = res.data.total;
        }
      });
    },

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getUserLvRecord();
    },
   

  },
};
</script>
<style lang="scss" scoped>
.balance {
  padding: 0.15rem;
  box-sizing: border-box;
}

.table-search {
  display: flex;
  margin-bottom: 0.15rem;
}

.search_L {
  display: flex;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
